import React, {useEffect, useState} from "react";
import Chip from "@mui/material/Chip";

const ChipSelector = ({ months, onQueryChange }) => {
    const [selectedChips, setSelectedChips] = useState([]); // Manages multiple selections

    useEffect(() => {
        setSelectedChips(["Tous"]);
        if (onQueryChange) onQueryChange(["Tous"]);
    }, []);

    const handleChipClick = (label) => {
        if (label === "Tous" && !selectedChips.includes("Tous")) {
            setSelectedChips(["Tous"]);
            if (onQueryChange) onQueryChange(["Tous"]); // Notify parent of selection change
        } else if (label === "Tous" && selectedChips.includes("Tous")) {
            setSelectedChips([]);
            if (onQueryChange) onQueryChange([]);
        } else {
            const updatedSelectedChips = selectedChips.includes(label)
                ? selectedChips.filter((chip) => chip !== label) // Remove if already selected
                : [...selectedChips.filter((chip) => chip !== "Tous"), label]; // Add if not selected and remove "Toutes"

            setSelectedChips(updatedSelectedChips); // Update state
            if (onQueryChange) onQueryChange(updatedSelectedChips); // Notify parent of selection change
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "start",
                justifyContent: "start",
                width: "100%",
            }}
        >
            <Chip
                style={{
                    background:
                        selectedChips.includes("Tous")
                            ? "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)"
                            : "transparent",
                    color: selectedChips.includes("Tous") ? "white" : "black",
                    border: selectedChips.includes("Tous") ? "1px solid" : "1px solid black",
                    margin: "3px",
                    fontWeight: "400",
                    lineHeight: "20px",
                }}
                variant="filled"
                label="Tous"
                onClick={() => handleChipClick("Tous")}
            />
            {months.map((month, index) => (
                <Chip
                    key={index}
                    style={{
                        background:
                            selectedChips.includes(month)
                                ? "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)"
                                : "white",
                        color: selectedChips.includes(month) ? "white" : "black",
                        border: selectedChips.includes(month) ? "1px solid" : "1px solid black",
                        margin: "3px",
                        fontWeight: "400",
                        lineHeight: "20px",
                    }}
                    variant="filled"
                    label={month}
                    onClick={() => handleChipClick(month)}
                />
            ))}
        </div>
    );
};

export default ChipSelector;
