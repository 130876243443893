import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const RescheduleTimePicker = ({ onTimeSelected, selectedTime }) => {
    const timeOptions = [
        0, 6, 12, 18, 24, 36, 48, 60, 72, 84, 96
    ];

    const [value, setValue] = useState(selectedTime);

    useEffect(() => {
        if (selectedTime && selectedTime.length > 2) {
            setValue(selectedTime);
        }
    }, [selectedTime]);

    const handleTimeChange = (_, newValue) => {
        setValue(newValue);
        onTimeSelected(newValue);
    };

    const getOptionStyle = () => ({
        borderRadius: "15px",
        margin: "10px 0",
        background: "#DAE0E5",
        padding: "15px",
        color: "#000",
    });

    const transformOptions = (option) => {
        if (option===0){
            return "Aucune"
        }
        else {
            return option.toString() + "h";
        }
    };

    return (
        <div style={{width:"40%", marginTop:"10px", alignSelf:"start", justifySelf:"start"}}>
            <Autocomplete
                PopperComponent={(props) => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                        {props.children}
                    </div>
                )}
                disableClearable={true}
                className="custom-autocomplete button-autocomplete"
                style={{ background: "#F1F5F8", borderRadius: "15px", width: "100%" }}
                onChange={handleTimeChange}
                value={value}
                options={timeOptions}
                getOptionLabel={(option) => transformOptions(option)}
                renderOption={(props, option, { selected }) => {
                    return <li style={getOptionStyle()} {...props}>{transformOptions(option)}</li>;
                }}
                renderInput={({ inputProps, ...params }) => (
                    <TextField {...params}
                               label="Durée"
                               inputProps={{ ...inputProps, readOnly: true }}/>
                )}
            />
        </div>
    );
};

export default RescheduleTimePicker;
