import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {
    getUser,
    getUserPendingRdv,
    getUserPostCreateStatus,
    getUserPresta,
    getUserSubscription,
    updateSortIndexes
} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import LazyLoad from 'react-lazyload';
import playIc from "../assets/playSvg.svg";
import notificationIc from "../assets/notificationIcon.svg";
import icLocationWhite from "../assets/locationWhite.svg";
import icEyeWhite from "../assets/eyeSlashWhite.svg";
import icDepositWhite from "../assets/depositWhite.svg";
import icSortHome from "../assets/icSortHome.svg";

import {
    FaDailymotion, FaEnvelope,
    FaFacebook, FaFacebookMessenger, FaGlobe,
    FaInstagram, FaPaperclip, FaPinterest, FaQuestion,
    FaSearch,
    FaShareAlt, FaSnapchat, FaStripe, FaThumbtack,
    FaTiktok, FaWhatsapp, FaYoutube,
} from "react-icons/fa";
import {FaPaypal} from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
    Backdrop,
    Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import ShareDrawer from "./sharedrawer";
import {useDataContext} from "./datacontext";
import imglogo from "../assets/mdi_images.png";
import rec from "../assets/rec.png";
import {HeaderHome} from "./headerHome";
import icLydia from "../assets/iconLydiaWhite.svg";
import icPaylib from "../assets/iconPaylibWhite.svg";
import icWise from "../assets/iconWiseWhite.svg";
import icRevolut from "../assets/iconRevolutWhite.svg";
import icLinktree from "../assets/iconLinktreeWhite.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinksWhite.svg";
import icSumUp from "../assets/iconSumUpWhite.svg";
import deleteic from "../assets/icdeletetag.svg";
import icTimer from "../assets/icTimer.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const faImageStyle = {
    position: 'absolute !important',
    top: '5px !important',
    right: '5px !important',
    opacity:'100%',
    color: '#F2F2F2',
    width: '30px',
    height: '30px',
    zIndex: 1,
};

const faStatusStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    opacity:'100%',
    zIndex: 1,
};

const faPinStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    opacity:'100%',
    zIndex: 1,
};

export default function Home() {

    const { pathname } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const { data, updateData } = useDataContext();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const stateData = location?.state || false;
    const [isPostCreate, setIsPostCreate] = useState(stateData?.isPostCreate || false);

    //TODO all pages => Run the app without internet - display relevant messages -
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        updateData({});
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user || user.isAnonymous) {
                navigate("/");
            } else {
                setShowBackdrop(true);
                const fetchData = async () => {
                    try {
                        const postCreateDone = await getUserPostCreateStatus(user.uid);
                        return postCreateDone;
                    } catch (error) {
                        console.error('Error retrieving postCreateDone status:', error);
                    }
                };
                // Call the fetchData function directly
                const displayPage = await fetchData(user.uid);
                if (!displayPage){
                    //User needs to set up minimal info before seeing this page
                    setShowBackdrop(false);
                    navigate("/info");
                } else {
                    //fetch page data
                    async function fetchUserData() {
                        try {
                            const userData = await getUser(user?.uid);
                            const userPresta = await getUserPresta(user?.uid);
                            const subObj = await getUserSubscription(firebase.auth().currentUser.uid);
                            const notifs = await getUserPendingRdv(user.uid, new Date());
                            setNotifications(notifs);
                            if (subObj?.status){
                                setSubStatus(subObj.status);
                                setRdvLeft(50 - subObj.count);
                            } else {
                                setSubStatus("free");
                                setRdvLeft(0);
                            }
                            if (userPresta.length>0){
                                setPrestaArray(userPresta);
                            }
                            setUserObject(userData);
                        } catch (e){
                            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                        }
                        setShowBackdrop(false);
                    }
                    await fetchUserData();
                    setOpenBienvDialog(isPostCreate);
                    //setOpenBienvDialog(true);
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    const [userObject, setUserObject] = useState({});
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [prestaArray, setPrestaArray] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const [rdvLeft, setRdvLeft] = useState(0);
    const [notifications, setNotifications] = useState(0);
    //snackbar logic

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [deployTags, setDeployTags] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [filterTags, setFilterTags] = useState([]);
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const dialRef = useRef(null);
    const [subStatus, setSubStatus] = useState("");

    const dialBienvRef = useRef(null);
    const [openBienvDialog, setOpenBienvDialog] = useState(false);

    const [openReorderPrestaDialog, setOpenReorderPrestaDialog] = useState(false);
    const [tempPrestaArray, setTempPrestaArray] = useState([]);

    useEffect(() => {
        if (openTagDialog && dialRef.current) {
            dialRef.current.focus();
        }
    }, [openTagDialog]);

    useEffect(() => {
        // Clear the localStorage data when the component mounts
        localStorage.removeItem('currentState');
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    //TODO unify loading strategy
    /*if (Object.keys(userObject).length === 0) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }*/

    //Navigation
    const navigateToRdv = () => {
        navigate("/rdv");
    };

    const navigateToPreferences = () => {
        navigate("/preferences");
    };

    const navigateToSettings = () => {
        navigate("/settings");
    };

    const navigateToNewPresta = () => {
        navigate("/newpresta");
    };

    const handlePrestaNavigation = (documentId) => {
        navigate("/presta/"+documentId);
    };

    const navigateToSub = () => {
        navigate("/subscription");
    };

    const toggleDrawer = async () => {
        //setDrawerIsOpen((prev) => !prev);

        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Partager ma page faismoibg',
                    text: 'Voici le lien vers ma page faismoibg',
                    url: 'https://faismoibg.com/' + userObject.urlPerso,
                });
            } else {
                console.log('Web Share API is not supported');
                setDrawerIsOpen((prev) => !prev);
            }
        } catch (error) {
            console.error('Error sharing content:', error);
        }
    };

    const handleSocialClick = (link) => () => {
        window.open(link, '_blank');
    };


    const handleMenuToggle = (event) => {
        event.stopPropagation();
        setDeployTags(!deployTags); // Close the menu by setting anchorEl to null
    };

    const divRef = useRef(null); // Create a ref for your div element

    useEffect(() => {
        function handleClickOutside(event) {
            if (divRef.current && !divRef.current.contains(event.target)) {
                // Click occurred outside of the div, so you can trigger your close function here
                // For example, you can set deployTags to false to close the div
                setDeployTags(false);
            }
        }

        // Attach the event listener when deployTags is true
        if (deployTags) {
            document.addEventListener('click', handleClickOutside);
        } else {
            // Clean up the event listener when deployTags is false to avoid memory leaks
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, [deployTags]);

    const handleTempSelection = (keyword) => {
        setSelectedTags((prevSelectedTags) => {
            // Check if the keyword is already in selectedTags
            const isTagSelected = prevSelectedTags.includes(keyword);
            // If the tag is already selected, remove it; otherwise, add it
            if (isTagSelected) {
                return prevSelectedTags.filter(tag => tag !== keyword);
            } else {
                return [...prevSelectedTags, keyword];
            }
        });
    };

    const handleValidateSelection = () => {
        setFilterTags(selectedTags);
        setDeployTags(false);
    };

    const handleTagDelete = (tag) => {
        const newFilterTags = filterTags.filter(item => item !== tag);
        const newSelectedTags = selectedTags.filter(item => item !== tag);
        setFilterTags(newFilterTags);
        setSelectedTags(newSelectedTags);
    };

    const handleOpenTagsDialog = () => {
        setOpenTagDialog(true);
    };

    const handleTagDialogCloseOutside = (event) => {
        setOpenTagDialog(false);
    };

    const handleBienvDialogCloseOutside = (event) => {
        setOpenBienvDialog(false);
    };

    const handleTagDialogClose = (event) => {
        setFilterTags(selectedTags);
        setDeployTags(false);
        setOpenTagDialog(false);
    };

    //drag and drop

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Early return if the position is the same
        if (sourceIndex === destinationIndex) {
            return;
        }

        let newOrder = Array.from(tempPrestaArray.filter((p)=>p.status==="production")
            .sort((a,b)=>{
                // Handle cases where isSorted doesn't exist or is false
                if (!a.isSorted || !b.isSorted) {
                    if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                    if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                    // Both are not sorted, sort by timestamp in descending order
                    return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                }
                // Both are sorted, compare by index
                return a.sortIndex - b.sortIndex;
            }));

        // Moving the item within the array
        const [removed] = newOrder.splice(sourceIndex, 1);
        newOrder.splice(destinationIndex, 0, removed);
        newOrder = newOrder.map((item, index) => ({
            ...item,
            isSorted: true,
            sortIndex: index
        }));

        // Create a map of the updated fields
        const newOrderMap = new Map(
            newOrder.map((item) => [item.documentId, { isSorted: item.isSorted, sortIndex: item.sortIndex }])
        );

        // Update the original prestaArray with the new `isSorted` and `index` values
        const updatedPrestaArray = prestaArray.map((item) =>
            newOrderMap.has(item.documentId)
                ? { ...item, ...newOrderMap.get(item.documentId) } // Only update isSorted and index
                : item // Keep unchanged for non-matching
        );

        setTempPrestaArray(updatedPrestaArray);
    };

    const handleReorderImagesDialogCloseOutside = (event) => {
        setOpenReorderPrestaDialog(false);
    };

    const handleReorderImagesClose = async () => {
        setShowBackdrop(true);
        setOpenReorderPrestaDialog(false);
        const dataToUpdate = tempPrestaArray
            .filter(item => item.isSorted === true && item.sortIndex !== undefined)
            .map(item => ({
                documentId: item.documentId,
                isSorted: item.isSorted,
                sortIndex: item.sortIndex
            }));

        try {
            await updateSortIndexes(dataToUpdate);
            setShowBackdrop(false);
        } catch (e) {
            handleOpenSnackbar("Quelque chose s'est mal passé. Contactez nous. ", e.message);
            setShowBackdrop(false);
            console.error('Error', e.message);
        }
        setPrestaArray(tempPrestaArray);
    };

    useEffect(() => {
        if (openReorderPrestaDialog){
            setTempPrestaArray(prestaArray);
        }
    }, [openReorderPrestaDialog]);

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {Object.keys(userObject).length === 0 ? (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%", flexDirection:"column",
                            justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                            <Skeleton style={{marginTop:"25px"}} variant="circular" width={110} height={110} />
                            <Skeleton style={{borderRadius:"5px", marginTop:"20px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                            <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="60%" height={60}/>
                            <Skeleton style={{marginTop:"35px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        </div>
                    </div>
                ) :
            (<div className="sub-container-no-top">
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                        margin: 'auto',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:'#F1F1F1',
                        position:"relative"
                    }}
                >
                    {userObject.imageUrl ? (
                        <img onLoad={handleImageLoad}
                             className="fade-in"
                             src={userObject.imageUrl} alt="Uploaded" style={{
                            borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', minWidth:"100px" }} />
                    ) : (
                        <img src={rec} style={{ borderRadius: '50%', width: '110px', height: '110px', objectFit: 'cover' }} ></img>
                    )}
                </div>
                <div style={{width:"80%", marginTop:"20px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <span style={{fontFamily:"Nunito Black", color:"black", fontSize:"20px", fontWeight:"900", lineHeight:"24px"}}>{userObject.pseudo || ""}</span>
                </div>
                <div style={{width:"90%",maxWidth:"90%", marginTop:"20px", display:"flex"}}>
                    <span style={{width:"100%", color:"black", fontSize:"16px",
                    fontWeight:"400", textAlign:"center", lineHeight:"22px",
                    whiteSpace:"pre-line",maxWidth: "100%", overflowWrap:"break-word"}}>{userObject.presentation || ""}</span>
                </div>
                <div style={{ width: "100%", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", flexWrap:"wrap" }}>
                        {userObject?.socialLinks?.map((socialLink, index) => {
                            if (socialLink?.link?.length > 0) {
                                const iconComponent = {
                                    facebook: <FaFacebook style={{ fontSize: '1.25rem', color: "white" }} />,
                                    instagram: <FaInstagram style={{ fontSize: '1.25rem', color: "white" }} />,
                                    tiktok: <FaTiktok style={{ fontSize: '1.25rem', color: "white" }} />,
                                    messenger: <FaFacebookMessenger style={{ fontSize: '1.25rem', color: "white" }} />,
                                    pinterest: <FaPinterest style={{ fontSize: '1.25rem', color: "white" }} />,
                                    snapchat: <FaSnapchat style={{ fontSize: '1.25rem', color: "white" }} />,
                                    whatsapp: <FaWhatsapp style={{ fontSize: '1.25rem', color: "white" }} />,
                                    paypal: <FaPaypal style={{ fontSize: '1.25rem', color: "white" }} />,
                                    lydia: <img src={icLydia} style={{ color: "white", width:"20px", height:"20px" }} />,
                                    paylib: <img src={icPaylib} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover",scale:"1.2" }} />,
                                    stripe: <FaStripe style={{ fontSize: '1.25rem', color: "white", scale:"1.3"  }} />,
                                    sumup: <img src={icSumUp}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover"}} />,
                                    wise: <img src={icWise} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.8" }} />,
                                    revolut: <img src={icRevolut} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                    linktree: <img src={icLinktree} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover" }} />,
                                    linkinbio: <FaPaperclip style={{ fontSize: '1.25rem', color: "white" }} />,
                                    beacons: <img src={icBeacons} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                    allmylinks: <img src={icAllMyLinks}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                    youtube: <FaYoutube style={{ fontSize: '1.25rem', color: "white" }} />,
                                    dailymotion: <FaDailymotion style={{ fontSize: '1.25rem', color: "white" }} />,
                                    email: <FaEnvelope style={{ fontSize: '1.25rem', color: "white" }} />,
                                    site: <FaGlobe style={{ fontSize: '1.25rem', color: "white" }} />,
                                }[socialLink?.network];

                                return (
                                    <a href={socialLink.link} target="_blank" key={socialLink?.network+index}>
                                        <IconButton className="button-style-noborders-socialicon" key={index} style={{ padding: "10px", marginLeft: "5px", marginRight: "5px", marginTop:"10px" }}>
                                            {iconComponent}
                                        </IconButton>
                                    </a>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
                {(subStatus==="trialing" || subStatus==="active") && (
                    <div className="sub-container-no-top">
                        <div onClick={()=>navigate("/notifications")} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "15px",
                            borderRadius: "15px",background: "#FFF", boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"50px", alignContent:"center", width:"100%",
                            cursor:"pointer"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"start", alignContent:"center", alignItems:"center",margin:"20px 30px"}}>
                                <img src={notificationIc}/>
                                <span style={{marginLeft:"12px"}}>{notifications?.length<2 ? notifications?.length + " demande" : notifications?.length + " demandes"} de réservation</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="field-container" style={{marginTop:"50px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                    <div className="title-field-container" style={{width:"70%"}}>
                                  <span className="text-field-style">
                                      Choisir une prestation
                                  </span>
                    </div>
                    <div style={{width:"30%", justifyContent:"end", display:"flex"}}>
                        <Chip onClick={handleOpenTagsDialog}
                              style={{ marginBottom:"10px",
                                  background:"black", cursor:"pointer",
                                  color:"white", fontWeight:"400", justifySelf:"end", padding:"5px 10px", fontSize:"14px"}} label="Filtrer">
                        </Chip>
                    </div>
                </div>

                <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                    <TextField style={{width:"100%"}} className="field-style" id="outlined-basic" label="Rechercher" variant="outlined" value={searchQuery}
                               onChange={(e) => setSearchQuery(e.target.value)}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <FaSearch style={{color:"black"}} />
                                       </InputAdornment>
                                   ),
                               }}/>

                </div>


                {/*JustifyContent end in case you enable the "recent" option*/}
                <div style={{width:"100%", marginTop:"10px", display:"flex", justifyContent:"start"}}>
                    <div style={{display:"flex", width:"80%", flexDirection:"column"}}>

                        <div style={{display:"flex", maxWidth:"95%", flexWrap:"wrap"}}>
                            {filterTags.map((tag, index)=>(
                                <Chip
                                    onDelete={() => handleTagDelete(tag)}
                                    key={index}
                                    deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                    style={{
                                        background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", margin:"5px",
                                        color:"white", fontWeight:"400", placeSelf:"end", lineHeight:"20px"}}
                                    label={tag}>
                                </Chip>
                            ))}
                        </div>

                    </div>

                    {/*<Chip onClick={() => setSortAscending(!sortAscending)}
                        style={{width:"20%", marginTop:"5px",
                            background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", cursor:"pointer", color:"white", fontWeight:"400", justifySelf:"end"}} label={sortAscending ? "Ancien" : "Récent"}></Chip>*/}
                </div>
                <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                    <Chip onClick={()=>setOpenReorderPrestaDialog(true)}
                          icon={<img style={{width:"16px", height:"16px"}} src={icSortHome}></img>}
                          style={{marginTop:"40px", background:"white", cursor:"pointer", border:"1px solid black",
                              color:"black", fontWeight:"400", padding:"8px 10px", fontSize:"14px"}}
                          label="Ordre des prestations">
                    </Chip>
                </div>
                <div className="image-gallery-container" style={{marginTop:"20px"}}>
{/*
                    <HomeListPresta onDragEnd={onDragEnd} prestaArray={prestaArray} filterTags={filterTags} searchQuery={searchQuery} sortAscending={sortAscending} handlePrestaNavigation={handlePrestaNavigation} faStatusStyle={faStatusStyle} faPinStyle={faPinStyle} handleImageLoad={handleImageLoad}></HomeListPresta>
*/}
                    {prestaArray.filter((doc) => {
                        // Check if filterTags is empty or if doc.keywords contains any of the tags in filterTags
                        return (
                            filterTags.length === 0 ||
                            filterTags.some((tag) =>
                                doc.keywords.some((keyword) => keyword.includes(tag))
                            )
                        );
                    }).filter((doc)=>
                        searchQuery.trim() === ''
                            ? true // Return all results if searchQuery is empty
                            : doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            doc.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            doc.price.toString().includes(searchQuery.trim())
                    ) .sort((a, b) => {
                        // Both are either pinned or not, proceed to check status
                        if (a.status === "production" && b.status === "production") {

                            if (!a.isSorted || !b.isSorted) {
                                if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                                if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                                // Both are not sorted, sort by timestamp in descending order
                                return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                            }
                            // Both are sorted, compare by index
                            return a.sortIndex - b.sortIndex;

                        } else if (a.status === "production" && b.status === "draft") {
                            return -1; // 'a' comes first because it is in "production"
                        } else if (a.status === "draft" && b.status === "production") {
                            return 1; // 'b' comes first because it is in "production"
                        } else {
                            // Both are in "draft", sort by date
                            if (sortAscending) {
                                return new Date(a.timestamp.seconds * 1000) - new Date(b.timestamp.seconds * 1000); // Sort in ascending order
                            } else {
                                return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000); // Sort in descending order
                            }
                        }
                    }) .map((doc, index) => (
                        <div key={index} className="image-container-home"
                             onClick={() => handlePrestaNavigation(doc.documentId)} >
                            <div className="image-wrapper-home">
                                 <div className="custom">
                                    <div style={{display:"flex", flexDirection:"row"}}>
                                        {doc.imageLinks.some(item => item.vlink.length > 0) ? (
                                            // Render a different image when the condition is met
                                            <img src={playIc} style={{ borderRadius: "none", width:"19px", height:"19px"}} />
                                        ) : (
                                            // Render the default image when the condition is not met
                                            <img src={imglogo} style={{ borderRadius: "none", width:"19px", height:"19px"}} />
                                        )}
                                        {
                                            !doc?.isVisible && (
                                                <img src={icEyeWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"21px", height:"21px" }} />
                                            )
                                        }
                                        {
                                            doc?.isAddressVisible && (
                                                <img src={icLocationWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"20px", height:"20px" }} />
                                            )
                                        }
                                        {
                                            doc?.isDeposit && (
                                                <img src={icDepositWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"21px", height:"21px" }} />
                                            )
                                        }
                                    </div>

                                </div>

                                <div style={faStatusStyle}>
                                    <div style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                borderRadius:"99px",
                                                background: doc.status==="draft" ? "linear-gradient(90deg, #8A9699 0%, #434849 100%)" : "linear-gradient(90deg, #06AC2C 0%, #28D850 100%)"
                                            }}>
                                                <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                    {doc.status==="draft" ? "Brouillon" : "Publié"}
                                                </span>
                                            </div>
                                        </div>
                                        {doc?.isAutoBooking && !doc?.isValidation && (
                                            <div style={{display:"flex"}}>
                                                <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                    borderRadius:"99px",
                                                    background: "linear-gradient(90deg, #6834FA 9.8%, #9747FF 100%)",
                                                    marginTop:"8px"
                                                }}>
                                                    <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                        Auto
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {!doc?.isAutoBooking && !doc?.isValidation && (
                                            <div style={{display:"flex"}}>
                                                <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                    borderRadius:"99px",
                                                    background: "linear-gradient(90deg, #F33636 0%, #FF5E00 93.67%)",
                                                    marginTop:"8px"
                                                }}>
                                                        <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                            Manuelle
                                                        </span>
                                                </div>
                                            </div>
                                        )}
                                        {doc?.isAutoBooking && doc?.isValidation && (
                                            <div style={{display:"flex"}}>
                                                <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                    borderRadius:"99px",
                                                    background: "linear-gradient(90deg, #FA3464 7.74%, #9747FF 99.4%)",
                                                    marginTop:"8px"
                                                }}>
                                                        <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                            Validation
                                                        </span>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>

                                {/*{doc.isPinned && (
                                    <div style={faPinStyle}>
                                        <FaThumbtack style={{color:"white", width:"20px", height:"20px"}}></FaThumbtack>
                                    </div>
                                )}*/}

                                {doc.imageLinks && doc.imageLinks.length > 0 && doc.imageLinks[0].imageLink.length > 0 ? (
                                        <LazyLoad>
                                    <img onLoad={handleImageLoad}
                                         className="fade-in imstyle" src={doc.imageLinks[0].imageLink} alt={`Image ${index + 1}`} />
                                        </LazyLoad>
                                ) : (
                                    <LazyLoad>
                                    <img onLoad={handleImageLoad}
                                         className="fade-in imstyle" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." alt={`Image ${index + 1}`} />
                                    </LazyLoad>
                                )}
                            </div>
                            <div className="image-text-home">
                                <span>{doc.title}</span>
                            </div>
                        </div>
                    ))}
                    {prestaArray.length===0 && (
                            <div style={{display: "flex", width:"100%", padding: "20px", flexDirection: "column", justifyContent: "start", marginTop:"30px",
                                alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                    <span style={{lineHeight: "24px"}}>
                                        Vous n'avez pas encore créé de prestation
                                    </span>
                            </div>
                    )}
                    {subStatus!=="trialing" && subStatus!=="active" && (
                        <div style={{marginTop:"80px", display: "flex", width:"100%", padding: "20px", flexDirection: "column", justifyContent: "start",
                            alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                            <span style={{color:"#4808FF"}}>
                                Pour info ! ⚡
                            </span>
                            <span style={{marginTop:"10px", lineHeight: "24px"}}>
                                Il vous reste <span style={{fontWeight:700}}>{rdvLeft}</span> réservations sur <span style={{fontWeight:700}}>50</span> pour ce mois. Le compteur se rechargera le 1er du mois suivant.
                            </span>
                            <span style={{marginTop:"10px", lineHeight: "24px"}}>
                                Seuls les comptes avec forfait premium peuvent bénéficier de plus de 50 rendez-vous par mois 🥰✨
                            </span>
                        </div>
                    )}
                    {subStatus!=="trialing" && subStatus!=="active" && (
                        <Button disableElevation={true} onClick={() => navigateToSub()}
                                className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"16px"}}>
                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                S'abonner
                            </Typography>
                        </Button>
                    )}

                </div>

                <div style={{position:"relative"}}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showBackdrop}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>

                <div className="button-container" style={{marginTop:"80px"}}>
                    <Button style={{marginBottom:"100px"}} onClick={toggleDrawer} startIcon={<FaShareAlt style={{color:"white"}}></FaShareAlt>} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{  marginLeft:"8px", textTransform: 'none', color:'#FFFFFF' }}>
                            Partagez votre page
                        </Typography>
                    </Button>
                </div>

                <div style={{display:"flex"}}>
                    <ShareDrawer urlPerso={userObject.urlPerso} isOpen={drawerIsOpen} onClose={toggleDrawer} />
                </div>
                <Dialog open={openReorderPrestaDialog} onClose={handleReorderImagesDialogCloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }}
                >

                    <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", paddingTop:"16px",
                        paddingBottom:"1px", paddingLeft:"24px", paddingRight:"24px", flexDirection:"column"}}>
                        <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"20px", alignSelf:"center"}}>Changer l'ordre des prestations</span>
                        <span style={{lineHeight:"21px", marginBottom:"5px", display:"flex",fontSize:"16px", fontWeight:400, marginTop:"15px"}}>Appuyez longuement pour séléctionner une image, puis glissez la vers sa nouvelle position ✨</span>
                    </DialogTitle>
                    <DialogContent style={{backgroundColor:"white"}}>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="vertical">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            scrollBehavior: "smooth",
                                            overflowX: "visible",
                                            overflowY: "visible"
                                        }}
                                    >
                                        {tempPrestaArray.filter((e)=> e.status==="production")
                                            .sort((a,b)=>{
                                                // Handle cases where isSorted doesn't exist or is false
                                                if (!a.isSorted || !b.isSorted) {
                                                    if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                                                    if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                                                    // Both are not sorted, sort by timestamp in descending order
                                                    return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                                                }
                                                // Both are sorted, compare by index
                                                return a.sortIndex - b.sortIndex;
                                            })
                                            .map((presta, index) => (
                                            <Draggable key={index} draggableId={String(index)} index={index}>
                                                {(provided, snapshot) => (
                                                    <div style={{display:"flex", flexDirection:"column", width:"100%",
                                                            ...provided.draggableProps.style,
                                                            ...(index === tempPrestaArray.length - 1 ? { marginRight: "15px" } : {}),
                                                        }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div style={{boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                                            background:"white",
                                                            marginTop: index === 0 ? "16px" : "0px",
                                                            borderRadius:"15px",
                                                            marginBottom:"16px",
                                                            display:"flex",
                                                            width:"100%"}}>
                                                            <div
                                                                //ref={provided.innerRef}
                                                                //{...provided.draggableProps}
                                                                //{...provided.dragHandleProps}
                                                                style={{
                                                                    //...provided.draggableProps.style,
                                                                    display: "flex",
                                                                    marginTop: "15px",
                                                                    marginLeft: "15px",
                                                                    marginBottom: "15px",
                                                                    alignItems:"center",
                                                                    margin:"12px"
                                                                    //...(index === prestaArray.length - 1 ? { marginRight: "15px" } : {}),
                                                                }}
                                                            >
                                                                <img onLoad={handleImageLoad} style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                    objectFit: "cover",
                                                                    //boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                                                                    borderRadius: "10px",
                                                                }} className="fade-in" src={presta?.imageLinks && presta?.imageLinks?.length > 0
                                                                && presta?.imageLinks[0]?.imageLink?.length > 0
                                                                    ? presta?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."} alt={`Image ${index + 1}`} />
                                                                <span style={{display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    WebkitLineClamp: 4,
                                                                    fontWeight:600,
                                                                    marginLeft: "16px",
                                                                    justifySelf: "center",
                                                                    alignSelf: "center"}}>{presta?.title}</span>
                                                                {/*{imageUrl.vlink.length > 0 ? (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain", cursor: "pointer" }} src={playIc} alt="Play icon"/>
                                                        </div>
                                                    ) : (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain" }} src={imglogo} alt="Image logo"/>
                                                        </div>
                                                    )}*/}
                                                            </div>
                                                        </div>

                                                    </div>

                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>


                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                        <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                            <Button onClick={handleReorderImagesClose} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Enregistrer
                                </Typography>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <Dialog ref={dialRef} open={openTagDialog} onClose={handleTagDialogCloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }}
                >

                    <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                       <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Filtrer par catégorie</span>
                    </DialogTitle>
                    <DialogContent style={{backgroundColor:"white", maxHeight:"600px", overflowY:"auto"}}>
                        <div style={{display:"flex", width:"100%",flex: 1,
                            flexDirection:"column", paddingTop:"20px", paddingBottom:"20px"}}>
                            {Array.from(new Set(prestaArray.flatMap(doc => doc.keywords)))
                                .sort((a, b) => a.localeCompare(b))
                                .map((keyword, index, array) => (
                                <MenuItem onClick={()=> (handleTempSelection(keyword))}
                                          style={{
                                              zIndex:1000,
                                              background:selectedTags.includes(keyword)
                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                  : "#DAE0E5",
                                              marginBottom: "10px",
                                              padding: "10px 15px",
                                              borderRadius:"15px"
                                          }}
                                          key={index}
                                          value={keyword}
                                >
                                                            <span style={{width: "100%",
                                                                overflow: "hidden",
                                                                color:selectedTags.includes(keyword)
                                                                    ? "white"
                                                                    : "black",
                                                                fontWeight:selectedTags.includes(keyword)
                                                                    ? 700
                                                                    : 400,
                                                                fontSize:"16px",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"}}>
                                                                {keyword}
                                                            </span>
                                </MenuItem>
                            ))
                            }


                                {/*<div style={{display: "flex", width:"90%", flexDirection: "column", justifyContent: "start", marginTop:"10px", alignSelf:"center",
                                    alignItems: "start", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                    <span style={{lineHeight: "24px", padding:"20px"}}>
                                        Vous n'avez pas encore ajouté de tag à vos prestations
                                    </span>
                                </div>*/}
                            {prestaArray.every(item => item.keywords.length === 0) && (
                                <div style={{
                                    display: "flex",
                                    width: "90%",
                                    alignSelf:"center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: "20px"
                                }}>
                                      <span style={{
                                          lineHeight: "24px",
                                          padding: "20px",
                                          borderRadius: "15px",
                                          background: "#FFF",
                                          boxShadow: '0px 4px 15px 0px rgba(218, 224, 229, 0.90)'
                                      }}>
                                        Vous n'avez pas encore ajouté de tag à vos prestations
                                      </span>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                            <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                                <Button onClick={handleTagDialogClose} className="button-style button-style-noborders" variant="contained">
                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                        Enregistrer
                                    </Typography>
                                </Button>
                            </div>
                    </DialogActions>
                </Dialog>

                <Dialog ref={dialBienvRef} open={openBienvDialog} onClose={handleBienvDialogCloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }}
                >

                    <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none", flexDirection:"column"}}>
                        <span style={{marginTop:"30px", fontSize:"24px", fontWeight:900, width:"100%", textAlign:"center", fontFamily:"Nunito Black"}}>Bienvenue sur Faismoibg ✨</span>
                        <span style={{marginTop:"24px", fontSize:"16px", fontWeight:700, width:"100%", textAlign:"center", fontFamily:"Nunito"}}>Voici les premières étapes à suivre pour bien commencer.</span>
                    </DialogTitle>
                    <DialogContent style={{backgroundColor:"white", maxHeight:"600px", overflowY:"auto"}}>
                        <div style={{display:"flex", width:"100%",flex: 1,
                            flexDirection:"column", paddingTop:"20px", paddingBottom:"20px"}}>
                            <span style={{color:"#4808FF", fontSize:"16px", fontWeight:700}}>Etape 1. <span style={{color:"black"}}> Editez votre profil</span></span>
                            <img src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/giftuto2-ezgif.com-optimize.gif?alt=media&token=6ce9dd7e-4fae-4f33-83d2-91285e14f78c" alt="tutoimg1"
                                 width="248px" height="379px" style={{marginTop:"10px", alignSelf:"center"}}/>
                            <span style={{fontSize:"16px", marginTop:"10px"}}>
                                Indiquez votre nom de beauty artiste, vos thématiques de prestations, une bio, une url personnalisée pour votre page Faismoibg, vos réseaux sociaux et votre ou vos adresses postales.
                            </span>
                            <span style={{color:"#4808FF", fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Etape 2. <span style={{color:"black"}}> Paramétrez votre agenda</span></span>
                            <img src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/gifagenda-ezgif.com-optimize.gif?alt=media&token=cd4f836e-d061-48e2-923a-b996eddad317" alt="tutoimg2"
                                 width="248px" height="379px" style={{marginTop:"10px", alignSelf:"center"}}/>
                            <span style={{fontSize:"16px", marginTop:"10px"}}>
                                Définissez vos horaires de travail, heures de pause et jours d’indisponibilité. Et synchronisez votre compte Faismoibg avec votre agenda Google (fortement recommandé).
                            </span>
                            <span style={{color:"#4808FF", fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Etape 3. <span style={{color:"black"}}> Créez vos prestations</span></span>
                            <img src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/gifoptimized.gif?alt=media&token=ab8e6364-d07e-400d-bd6b-cc30479ad3de" alt="tutoimg2"
                                 width="248px" height="379px" style={{marginTop:"10px", alignSelf:"center"}}/>
                            <span style={{fontSize:"16px", marginTop:"10px"}}>
                                Proposez un titre, une description, une durée, un tarif et des tags, etc. Personnalisez l’affichage de vos prestations grâce aux nombreuses options proposées par la plateforme.
                            </span>
                            <div className="button-container" style={{marginTop:"50px", marginLeft:"30px", marginRight:"30px", alignSelf:"center"}}>
                                <Button onClick={handleBienvDialogCloseOutside} className="button-style-black button-style-noborders" variant="contained">
                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                        Merci c’est okay 😊
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>

                    </DialogActions>
                </Dialog>

                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>)}
        </div>

    )
}