import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDataContext} from "./datacontext";
import {getUserPresta} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Fade,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {FaSearch, FaThumbtack} from "react-icons/fa";
import Button from "@mui/material/Button";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import LazyLoad from "react-lazyload";
import imglogo from "../assets/imgIcon.svg";
import {HeaderHome} from "./headerHome";
import playIc from "../assets/playSvg.svg";
import deleteic from "../assets/icdeletetag.svg";
import icEyeWhite from "../assets/eyeSlashWhite.svg";
import icLocationWhite from "../assets/locationWhite.svg";
import icDepositWhite from "../assets/depositWhite.svg";


const faStatusStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    opacity:'100%',
    zIndex: 1,
};

const faPinStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    opacity:'100%',
    zIndex: 1,
};

export default function RdvPresta() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { data, updateData } = useDataContext();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        updateData({});
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);
                async function fetchUserData() {
                    try {
                        const userPresta = await getUserPresta(user?.uid);
                        if (userPresta.length>0){
                            const filteredPrestaArray = userPresta.filter(item => item.status !== "draft");
                            setPrestaArray(filteredPrestaArray);
                        }
                    } catch (e){
                        handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                    }
                    setShowBackdrop(false);
                }
                await fetchUserData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    const [showBackdrop, setShowBackdrop] = useState(true);
    const [prestaArray, setPrestaArray] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortAscending, setSortAscending] = useState(false);
    const [deployTags, setDeployTags] = useState(false);
    const divRef = useRef(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [filterTags, setFilterTags] = useState([]);
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const dialRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (divRef.current && !divRef.current.contains(event.target)) {
                // Click occurred outside of the div, so you can trigger your close function here
                // For example, you can set deployTags to false to close the div
                setDeployTags(false);
            }
        }
        // Attach the event listener when deployTags is true
        if (deployTags) {
            document.addEventListener('click', handleClickOutside);
        } else {
            // Clean up the event listener when deployTags is false to avoid memory leaks
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, [deployTags]);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handlePrestaNavigation = (documentId) => {
        navigate("/prestab/"+documentId);
    };

    const navigateBack = async () => {
        navigate('/rdv');
    }

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleMenuToggle = (event) => {
        event.stopPropagation();
        setDeployTags(!deployTags); // Close the menu by setting anchorEl to null
    };

    const handleTempSelection = (keyword) => {
        setSelectedTags((prevSelectedTags) => {
            // Check if the keyword is already in selectedTags
            const isTagSelected = prevSelectedTags.includes(keyword);
            // If the tag is already selected, remove it; otherwise, add it
            if (isTagSelected) {
                return prevSelectedTags.filter(tag => tag !== keyword);
            } else {
                return [...prevSelectedTags, keyword];
            }
        });
    };

    const handleValidateSelection = () => {
        setFilterTags(selectedTags);
        setDeployTags(false);
    };

    const handleTagDelete = (tag) => {
        const newFilterTags = filterTags.filter(item => item !== tag);
        const newSelectedTags = selectedTags.filter(item => item !== tag);
        setFilterTags(newFilterTags);
        setSelectedTags(newSelectedTags);
    };

    const handleOpenTagsDialog = () => {
        setOpenTagDialog(true);
    };

    const handleTagDialogCloseOutside = (event) => {
        setOpenTagDialog(false);
    };

    const handleTagDialogClose = (event) => {
        setFilterTags(selectedTags);
        setDeployTags(false);
        setOpenTagDialog(false);
    };

    return(
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
            <span className="title-style">Planifier un rendez-vous</span>

            <div className="field-container" style={{marginTop:"50px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}}>
                <div className="title-field-container" style={{width:"70%"}}>
                                  <span className="text-field-style">
                                      Choisir une prestations
                                  </span>
                </div>
                <div style={{width:"30%", justifyContent:"end", display:"flex"}}>
                    <Chip onClick={handleOpenTagsDialog}
                          style={{ marginBottom:"10px",
                              background:"black", cursor:"pointer",
                              color:"white", fontWeight:"400", justifySelf:"end", padding:"5px 10px", fontSize:"14px"}} label="Filtrer">
                    </Chip>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                <TextField style={{width:"100%"}} className="field-style" id="outlined-basic" label="Rechercher" variant="outlined" value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <FaSearch style={{color:"black"}} />
                                   </InputAdornment>
                               ),
                           }}/>
               {/*<div style={{display:"flex", width:"20%", justifyContent:"center"}}>
                    <IconButton
                        onClick={handleMenuToggle}
                        className="button-style-no-borders" variant="outlined"
                        style={{ zIndex: '1', borderRadius: '50%', padding: '10px'
                            , placeSelf:"center", justifySelf:"center", width:"50px", height:"50px", backgroundColor:"white" }}>
                        <img src={icFilter} style={{
                                transform: deployTags ?  'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                            width:"24px", height:"24px"}}/>
                    </IconButton>
                </div>*/}

            </div>

            {/*JustifyContent end in case you enable the "recent" option*/}
            <div style={{width:"100%", marginTop:"10px", display:"flex", justifyContent:"start"}}>
                <div style={{display:"flex", width:"80%", flexDirection:"column", placeSelf:"start"}}>
                    {deployTags && (
                        <Fade in={true} style={{ zIndex: 2 }}>
                            <div style={{ position: 'relative', left: 0, width:"100%"}} ref={divRef}>
                                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, maxWidth:"100%", maxHeight:"100px", minWidth:"100%"}}>
                                    <div style={{display:"flex",minWidth:"100%", width:"100%", flexDirection:"column", backgroundColor:"#F1F5F8", padding:"8px", borderRadius:"15px"}}>
                                        <div style={{ overflowY: "auto", maxHeight: "200px" }}>
                                            {Array.from(new Set(prestaArray.flatMap(doc => doc.keywords))).map((keyword, index) => (
                                                <MenuItem onClick={()=> (handleTempSelection(keyword))}
                                                          style={{
                                                              zIndex:1000,
                                                              background:selectedTags.includes(keyword)
                                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                                  : "#DAE0E5",
                                                              marginBottom:"15px",
                                                              padding: "15px 20px",
                                                              borderRadius:"15px"
                                                          }}
                                                          key={index}
                                                          value={keyword}
                                                >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            color:selectedTags.includes(keyword)
                                                                ? "white"
                                                                : "black",
                                                            fontWeight:selectedTags.includes(keyword)
                                                                ? 700
                                                                : 400,
                                                            fontSize:"16px",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            {keyword}
                                                        </span>
                                                </MenuItem>
                                            ))
                                            }
                                        </div>
                                        <div className="button-container" style={{marginTop:"15px"}}>
                                            <Button onClick={handleValidateSelection} style={{marginBottom:"15px"}} className="button-style button-style-noborders" variant="contained">
                                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                                    Valider
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    )}
                    <div style={{display:"flex", maxWidth:"95%", flexWrap:"wrap"}}>
                        {filterTags.map((tag, index)=>(
                            <Chip
                                key={index}
                                onDelete={() => handleTagDelete(tag)}
                                deleteIcon={<img src={deleteic} style={{width:"20px", height:"20px", marginRight:"8px"}}></img>}
                                style={{
                                    background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", margin:"5px", lineHeight:"20px",
                                    color:"white", fontWeight:"400", placeSelf:"end"}}
                                label={tag}>
                            </Chip>
                        ))}
                    </div>

                </div>

               {/* <Chip onClick={() => setSortAscending(!sortAscending)}
                      style={{width:"20%", marginTop:"5px",
                          background:"linear-gradient(90deg, #4808FF 0%, #9747FF 100%)", cursor:"pointer", color:"white", fontWeight:"400", justifySelf:"end"}} label={sortAscending ? "Ancien" : "Récent"}></Chip>*/}
            </div>

            <div className="image-gallery-container" style={{marginTop:"30px"}}>
                {prestaArray.filter((doc) => {
                    // Check if filterTags is empty or if doc.keywords contains any of the tags in filterTags
                    return (
                        filterTags.length === 0 ||
                        filterTags.some((tag) =>
                            doc.keywords.some((keyword) => keyword.includes(tag))
                        )
                    );
                }).filter((doc) =>
                    searchQuery.trim() === ''
                        ? true // Return all results if searchQuery is empty
                        : doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        doc.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        doc.price.toString().includes(searchQuery.trim())
                ) .sort((a, b) => {
                    if (!a.isSorted || !b.isSorted) {
                        if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                        if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                        // Both are not sorted, sort by timestamp in descending order
                        return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                    }
                    // Both are sorted, compare by index
                    return a.sortIndex - b.sortIndex;
                }) .map((doc, index) => (
                    <div key={index} className="image-container-home"
                         onClick={() => handlePrestaNavigation(doc.documentId)} >
                        <div className="image-wrapper-home">
                            <div className="custom">
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    {doc.imageLinks.some(item => item.vlink.length > 0) ? (
                                        // Render a different image when the condition is met
                                        <img src={playIc} style={{ borderRadius: "none", width:"19px", height:"19px"}} />
                                    ) : (
                                        // Render the default image when the condition is not met
                                        <img src={imglogo} style={{ borderRadius: "none", width:"19px", height:"19px"}} />
                                    )}
                                    {
                                        !doc?.isVisible && (
                                            <img src={icEyeWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"21px", height:"21px" }} />
                                        )
                                    }
                                    {
                                        doc?.isAddressVisible && (
                                            <img src={icLocationWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"20px", height:"20px" }} />
                                        )
                                    }
                                    {
                                        doc?.isDeposit && (
                                            <img src={icDepositWhite} style={{ borderRadius: "none", marginLeft:"8px",width:"21px", height:"21px" }} />
                                        )
                                    }
                                </div>

                            </div>

                            <div style={faStatusStyle}>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                            borderRadius:"99px",
                                            background: doc.status==="draft" ? "linear-gradient(90deg, #8A9699 0%, #434849 100%)" : "linear-gradient(90deg, #06AC2C 0%, #28D850 100%)"
                                        }}>
                                                <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                    {doc.status==="draft" ? "Brouillon" : "Publié"}
                                                </span>
                                        </div>
                                    </div>
                                    {doc?.isAutoBooking && !doc?.isValidation && (
                                        <div style={{display:"flex"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                borderRadius:"99px",
                                                background: "linear-gradient(90deg, #6834FA 9.8%, #9747FF 100%)",
                                                marginTop:"8px"
                                            }}>
                                                    <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                        Auto
                                                    </span>
                                            </div>
                                        </div>
                                    )}
                                    {!doc?.isAutoBooking && !doc?.isValidation && (
                                        <div style={{display:"flex"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                borderRadius:"99px",
                                                background: "linear-gradient(90deg, #F33636 0%, #FF5E00 93.67%)",
                                                marginTop:"8px"
                                            }}>
                                                        <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                            Manuelle
                                                        </span>
                                            </div>
                                        </div>
                                    )}
                                    {doc?.isAutoBooking && doc?.isValidation && (
                                        <div style={{display:"flex"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px",
                                                borderRadius:"99px",
                                                background: "linear-gradient(90deg, #FA3464 7.74%, #9747FF 99.4%)",
                                                marginTop:"8px"
                                            }}>
                                                        <span style={{color: "white", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                            Validation
                                                        </span>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            {/*{doc.isPinned && (
                                <div style={faPinStyle}>
                                    <FaThumbtack style={{color:"white", width:"20px", height:"20px"}}></FaThumbtack>
                                </div>
                            )}*/}

                            {/* Image */}
                            {doc.imageLinks && doc.imageLinks.length > 0 && doc.imageLinks[0].imageLink.length > 1 ? (
                                    <LazyLoad>
                                <img onLoad={handleImageLoad}
                                     className="fade-in imstyle" src={doc.imageLinks[0].imageLink} alt={`Image ${index + 1}`} />
                                    </LazyLoad>
                            ) : (
                                <LazyLoad>
                                <img onLoad={handleImageLoad}
                                     className="fade-in imstyle" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." alt={`Image ${index + 1}`} />
                                </LazyLoad>

                            )}
                        </div>

                        {/* Text below the image */}
                        <div className="image-text-home">
                            <span>{doc.title}</span>
                        </div>
                    </div>
                ))}
            </div>

            {showBackdrop && (
                <div style={{display:"flex", width:"100%", flexDirection:"column",
                    justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                    <Skeleton style={{marginTop:"5px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                </div>
            )}

            {prestaArray.length===0 && !showBackdrop && (
                <div className="date-card-nopresta">
                    <div style={{margin:"20px"}}>
                        <span>Aucune prestation publiée</span>
                    </div>
                </div>
            )}

            <div className="button-container" style={{marginTop:"70px"}}>
                <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                    <Typography variant="button" style={{ textTransform: 'none' }}>
                        Annuler
                    </Typography>
                </Button>
            </div>

            <Dialog ref={dialRef} open={openTagDialog} onClose={handleTagDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >

                <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                    <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Filtrer par catégorie</span>
                </DialogTitle>
                <DialogContent style={{backgroundColor:"white",maxHeight:"600px", overflowY:"auto"}}>
                    <div style={{display:"flex", width:"100%", flex: 1,
                        flexDirection:"column", paddingTop:"20px", paddingBottom:"20px"}}>
                        {Array.from(new Set(prestaArray.flatMap(doc => doc.keywords)))
                            .sort((a, b) => a.localeCompare(b))
                            .map((keyword, index, array) => (
                            <MenuItem onClick={()=> (handleTempSelection(keyword))}
                                      style={{
                                          zIndex:1000,
                                          background:selectedTags.includes(keyword)
                                              ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                              : "#DAE0E5",
                                          marginBottom: "10px",
                                          padding: "10px 15px",
                                          borderRadius:"15px"
                                      }}
                                      key={index}
                                      value={keyword}
                            >
                                                            <span style={{width: "100%",
                                                                overflow: "hidden",
                                                                color:selectedTags.includes(keyword)
                                                                    ? "white"
                                                                    : "black",
                                                                fontWeight:selectedTags.includes(keyword)
                                                                    ? 700
                                                                    : 400,
                                                                fontSize:"16px",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"}}>
                                                                {keyword}
                                                            </span>
                            </MenuItem>
                        ))
                        }
                        {prestaArray.every(item => item.keywords.length === 0) && (
                            <div style={{
                                display: "flex",
                                width: "90%",
                                alignSelf:"center",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "20px"
                            }}>
                                      <span style={{
                                          lineHeight: "24px",
                                          padding: "20px",
                                          borderRadius: "15px",
                                          background: "#FFF",
                                          boxShadow: '0px 4px 15px 0px rgba(218, 224, 229, 0.90)'
                                      }}>
                                        Vous n'avez pas encore ajouté de tag à vos prestations
                                      </span>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={handleTagDialogClose} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>
        </div>
    )
}