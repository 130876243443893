import React from 'react';
const DisplayCarousel = ({ imagesArray }) => {

    const fallBackUrl = "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."
    const handleImageLoad = (event) => {
        event?.target?.classList?.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    return (
        <div className="image-gallery-container">
            {imagesArray?.length>0 && imagesArray.map((imageUrl, index) => (
                <div key={index} className="image-wrapper">
                    <img onLoad={handleImageLoad}
                         onError={(e) => {
                             if (e?.target?.src!==fallBackUrl){
                                 e.target.onerror = null;
                                 e.target.src= fallBackUrl
                             }
                         }}
                         className="fade-in" src={imageUrl?.url} alt={`Image ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};

export default DisplayCarousel;