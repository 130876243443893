import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from '../src/components/error';
import reportWebVitals from './reportWebVitals';
import Create from "./components/create";
import { frFR as dataGridFrFR } from '@mui/x-data-grid';
import { frFR as coreFrFR } from '@mui/material/locale';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {
    createTheme, Dialog,
    DialogActions,
    DialogContent,
    TextField,
    ThemeProvider,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Footer from "./components/footer";
import PostCreate from "./components/postcreate";
import Home from "./components/home";
import Preferences from "./components/preferences";
import Settings from "./components/settings";
import NewPresta from "./components/newpresta";
import {frFR, LocalizationProvider} from "@mui/x-date-pickers";
import Newquestionnaire from "./components/newquestionnaire";
import {DataProvider} from "./components/datacontext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import Presta from "./components/presta";
import ModifyPresta from "./components/modifypresta";
import Rdv from "./components/rdv";
import RdvPresta from "./components/rdvpresta";
import RdvPrestaB from "./components/rdvprestab";
import AppointDate from "./components/appointdate";
import AgendaParam from "./components/agendaparam";
import {AgDataProvider} from "./components/agendaDataContext";
import AppointDay from "./components/appointday";
import AppointConfirm from "./components/appointconfirm";
import RdvDetails from "./components/rdvdetails";
import {ResetPassword} from "./components/resetpassword";
import {NewPassword} from "./components/newpassword";
import Subscription from "./components/subscription";
import {HeaderBeforeLogin} from "./components/headerBeforeLogin";
import ReactGA from "react-ga4";
import Button from "@mui/material/Button";
import Deposit from "./components/deposit";
import Notifications from "./components/notifications";
import RdvMonthView from "./components/rdvMonthView";
import Stats from "./components/stats";
import Bloc from "./components/bloc";
import ClientStats from "./components/clientstats";
import PrestaStats from "./components/prestastats";
import BlacklistStats from "./components/blackliststats";
import * as Sentry from "@sentry/react";

ReactGA.initialize("G-YF0ZB2Y2X4");


const router = createBrowserRouter([
    {
        path: "/",
        element:  <>
            <div className="container">
                <HeaderBeforeLogin/>
                <div className="container-col">
                    <App />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/create",
        element:  <>
            <div className="container">
                <HeaderBeforeLogin/>
                <div className="container-col">
                    <Create />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },

    {
        path: "/subscription",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Subscription />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/newpassword",
        element:  <>
            <div className="container">
                <HeaderBeforeLogin/>
                <div className="container-col">
                    <NewPassword />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/resetpassword",
        element:  <>
            <div className="container">
                <HeaderBeforeLogin/>
                <div className="container-col">
                    <ResetPassword />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/info",
        element:  <>
            <div className="container">
                <HeaderBeforeLogin/>
                <div className="container-col">
                    <PostCreate />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/home",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Home />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/preferences",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Preferences />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/newpresta",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <NewPresta />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/rdvpresta",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <RdvPresta />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/agendaparam",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <AgendaParam />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/rdv",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Rdv />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/rdvMonth",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <RdvMonthView />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/rdvdetails/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <RdvDetails />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/presta/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Presta />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/prestab/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <RdvPrestaB />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/modifypresta/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <ModifyPresta />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/appointdate/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <AppointDate />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/appointconfirm/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <AppointConfirm />
                        <div className="footer-container">
                            <Footer />
                        </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/appointday/:id",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <AppointDay />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "questionnaire",
        element: <>
            <div className="container">
                <div className="container-col">
                    <Newquestionnaire />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "deposit",
        element: <>
            <div className="container">
                <div className="container-col">
                    <Deposit />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/settings",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Settings />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/notifications",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Notifications />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/stats",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Stats />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/bloc",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <Bloc />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/clientstats",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <ClientStats />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/prestastats",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <PrestaStats />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
    {
        path: "/blacklist",
        element:  <>
            <div className="container">
                <div className="container-col">
                    <BlacklistStats />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
    },
]);

const AppTheme = () => {

    const themewrapper = createTheme({
        frFR, // x-date-pickers translations
        dataGridFrFR, // x-data-grid translations
        coreFrFR, // core translations
        palette: {
            primary: {
                main: '#FFFFFF', // Set your desired primary color here
            },
        },
        overrides: {
            MuiSnackbar: {
                root: {
                    width: '100%', // This will make the Snackbar stretch to full width
                },
            },
            MuiSnackbarContent: {
                root: {
                    backgroundColor: 'blue', // Replace with your desired background color
                    color: 'white', // Replace with your desired text color
                },
            },
        },
        typography:
            {
            "fontFamily": `"Nunito", sans-serif`,
            "fontSize": 16,
            "fontWeightLight": 600,
            "fontWeightRegular": 700,
            "fontWeightMedium": 800,
            button: {
                fontWeight: '700', // Add this line to set the global font weight for button variant
            },
        },
        components: {
            MuiPopper: {
                defaultProps: {
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: false,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: false,
                        },
                    ],
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: 400,
                        fontSize: "16px", // Set the desired font size for labels
                    },
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    // Override styles for the toolbar (which contains the buttons)
                    '& button': {
                        border: 'none !important', // Remove the border from all MUI Button components inside the DatePicker
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        borderRadius:"15px",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        // Your custom styles for MuiPaper-root and MuiPaper-elevation
                        // Add any other styles you want to override here
                        borderRadius:"15px",
                        marginTop:"8px"
                        //boxShadow: "none",
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        // Your custom styles for MuiPaper-root and MuiAutocomplete-paper
                        // Add any other styles you want to override here
                        background: "white",
                        borderRadius:"15px",
                        backgroundColor:"#F1F5F8",
                        //boxShadow:"none"
                    },
                    listbox:{
                        borderRadius:"15px",
                        backgroundColor:"#F1F5F8",
                        padding: "4px 4px",
                        boxShadow:"none"
                    },
                    popper:{
                        marginTop:"4px !important",
                        padding: "4px 4px",
                    }
                },
            },
            MuiAutocompleteOption: {
                styleOverrides: {
                    option: {
                        // Your custom styles for the option items
                        // Add any other styles you want to override here
                        borderRadius: "8px",
                        margin: "2px 0",
                        background: "#F1F5F8",
                        padding: "8px",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'black',
                    },
                    root: {
                        borderRadius: 999,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 15,
                        fontWeight: 400,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: "#F1F5F8",
                            '&.Mui-focused fieldset': {
                                borderColor: '#000000',
                            },
                        },
                        "& fieldset": { border: 'none' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000000',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                            color: '#000000',
                        },
                        '& input': {
                            fontSize: '16px !important', // Change the font size as needed
                        },
                        '& textarea': {
                            fontSize: '16px !important', // Change the font size as needed
                        },

                    },
                },
            },
        },
    });

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    //Display warning if user is in Instagram browser
    useEffect(() => {
        const checkInstagram = () => {
            const userAgent = navigator.userAgent;
            const isInstagram = userAgent.includes('Instagram');

            if (isInstagram) {
                setIsDialogInstaOpen(true);
            }

        };

        checkInstagram();
    }, []);

    const [isDialogInstaOpen, setIsDialogInstaOpen] = useState(false);

    const handleInstaClose = (event) => {
        setIsDialogInstaOpen(false);
    };

    return (
        //TODO Regularly uncomment strict mode : check that the app run smoothly with it
        /*<React.StrictMode>*/
            <ThemeProvider theme={themewrapper}>
                <div style={{ display: "flex", width: "100%" }}>
                    {/*<CookieConsent
                        location="bottom"
                        buttonText="D'accord"
                        cookieName="fmbg"
                        style={{ background: "#2B373B",fontSize: "14px" }}
                        buttonStyle={{fontSize: "14px", fontWeight:700, borderRadius:"15px", background:"#4808FF", color:"white" }}
                    >
                        En continuant votre navigation, vous acceptez l'utilisation de cookie par notre site afin d'améliorer l'expérience utilisateur.
                    </CookieConsent>*/}
                    <RouterProvider router={router} />
                    <Dialog
                        onClose={handleInstaClose}
                        open={isDialogInstaOpen}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }}>
                        <DialogContent style={{backgroundColor:"white"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Navigation
                            </span>
                            <span style={{marginTop:"20px"}}>
                                Vous naviguez sur faismoibg depuis le navigateur Instagram, si vous rencontrez un problème de fonctionnement, utilisez un autre navigateur.
                            </span>
                            </div>

                        </DialogContent>
                        <DialogActions style={{backgroundColor:"white"}}>
                            <div style={{width:"100%", display:"flex", marginBottom:"10px", justifyContent:"end"}}>
                                <Button className="button-style-noborders-lil" disableElevation={true} style={{backgroundColor:"black", color:"white", display:"flex", marginRight:"14px"}} variant="contained" onClick={handleInstaClose}>
                                    <Typography variant="button" style={{textTransform: 'none', fontSize:"14px", fontWeight:400}}>
                                        D'accord
                                    </Typography>
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            </ThemeProvider>
/*
        </React.StrictMode>
*/
    );
};
Sentry.init({
    dsn: "https://d6502dd844eb48a5153ca70ef2efcf36@o4508443072659456.ingest.de.sentry.io/4508443074887760",
    integrations: [
        Sentry.captureConsoleIntegration({
            levels: ['error'], // Only capture `console.error`
        })
    ],
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<React.StrictMode>*/
      <DataProvider>
          <AgDataProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <AppTheme />
              </LocalizationProvider>
          </AgDataProvider>
      </DataProvider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
