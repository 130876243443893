import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Chip, Skeleton, Typography} from "@mui/material";
import firebase from "firebase/compat/app";
import {HeaderHome} from "./headerHome";
import icDiamond from "../assets/diamondColored.svg";

export default function BlacklistStats() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showSkeleton, setShowSkeleton] = useState(true);

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowSkeleton(false)
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);
    const navigateHome = () => {
        navigate(-1);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">Blacklist</span>
                        <div style={{display:"flex", borderRadius:"15px", width:"100%",
                            boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                            lineHeight:"20px", marginTop:"40px"}}>
                            <span style={{marginBottom: "20px", marginLeft:"20px", marginRight:"20px",marginTop:"15px"}}>
                                 Cette fonctionnalité sera disponible courant décembre 2024 💜. Elle vous permettra d’empêcher quelqu’un de prendre rendez-vous sur votre page Faismoibg✨.
                            </span>
                        </div>
                        <div style={{width:"100%", justifyContent:"center", display:"flex", marginBottom:"40px"}}>
                            <Chip onClick={()=>navigate("/subscription")}
                                  icon={<img style={{width:"16px", height:"16px"}} src={icDiamond}></img>}
                                  style={{marginTop:"20px", background:"white", cursor:"pointer", border:"1px solid black",
                                      color:"black", fontWeight:"400", padding:"18px 18px", fontSize:"14px", borderRadius:"1000px"}}
                                  label="Faismoibg premium">
                            </Chip>
                        </div>

                        <div style={{height:"375px"}}>
                            <img onLoad={handleImageLoad} width={300}
                                 className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fblacklist.png?alt=media&token=73308d68-de0d-46bb-8bc5-645e36532393"/>
                        </div>

                        <div className="button-container" style={{marginTop: "70px"}}>
                            <Button style={{marginBottom: "100px"}} className="button-style-nogr button-style-borders"
                                    disableElevation variant="contained" onClick={navigateHome}>
                                <Typography variant="button" style={{textTransform: 'none'}}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                    </div>
                )}
        </div>
    )
}