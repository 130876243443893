import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import firebase from "firebase/compat/app";
import icTimer from "../assets/icTimer.svg";
import SnackbarWithCustomBackground from "./snackbar";
import {HeaderHome} from "./headerHome";
import ChipYears from "./ChipYears";
import ChipMonths from "./ChipMonths";
import {countRes} from "../firebase";

const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

const monthNames = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];

const yearsSince2023 = Array.from({length: new Date().getFullYear() - 2022}, (_, i) => (2023 + i) + '');

export default function Stats() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showNumberPrestaSkeleton, setShowNumberPrestaSkeleton] = useState(true);
    //const [yearsSince2023, setYearsSince2023] = useState(Array.from({length: new Date().getFullYear() - 2022}, (_, i) => (2023 + i) + ''));
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [numberOfResa, setNumberOfResa] = useState(0);
    //firebase.functions().useEmulator("127.0.0.1", 5001);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowSkeleton(false)
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);
    const navigateHome = () => {
        navigate("/home");
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    // Perform the database call whenever selectedYears or selectedMonths updates
    useEffect(() => {
        const fetchData = async () => {
            setShowNumberPrestaSkeleton(true);
            let yearArrayQuery = [];
            let monthArrayQuery = [];

            if (selectedYears.includes('Toutes')) {
                yearArrayQuery = yearsSince2023;
            } else if (selectedYears.length > 0) {
                yearArrayQuery = selectedYears;
            }

            if (selectedMonths.includes('Tous')) {
                monthArrayQuery = Object.values(monthMap);
            } else if (selectedMonths.length > 0) {
                monthArrayQuery = selectedMonths
                    .filter(key => key in monthMap) // Ensure the key exists in monthMap
                    .map(key => monthMap[key]);
            }

            try {
                const res = await countRes(monthArrayQuery, yearArrayQuery);
                setNumberOfResa(res?.count);
                setShowNumberPrestaSkeleton(false);
            } catch (e) {
                handleOpenSnackbar("Quelque chose s'est mal passé. Contactez nous. ", e.message);
                setShowNumberPrestaSkeleton(false);
                console.error('Error', e.message);
            }
        };

        fetchData(); // Call the async function
    }, [selectedYears, selectedMonths]);



    const yearChanged = (yearArray) => {
        setSelectedYears(yearArray);
    };

    const monthChanged = (monthArray) => {
        setSelectedMonths(monthArray);
    };


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">Info & Stats</span>
                        <div className="button-container">
                            <Button className="button-style-noborders client-stats-button" variant="contained"
                                    onClick={()=>navigate("/clientstats")}
                            >
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Clients
                                </Typography>
                            </Button>
                        </div>
                        <div className="button-container" style={{marginTop: "23px"}}>
                            <Button className="button-style-noborders presta-stats-button" variant="contained"
                                    onClick={()=>navigate("/prestastats")}
                            >
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Prestations
                                </Typography>
                            </Button>
                        </div>
                        <div className="button-container" style={{marginTop: "23px"}}>
                            <Button className="black-stats-button button-style-noborders" variant="contained"
                                    onClick={()=>navigate("/blacklist")}
                            >
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Blacklist
                                </Typography>
                            </Button>
                        </div>

                        <div style={{position:"relative"}}>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={showBackdrop}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>

                        <div className="field-container">
                            <div className="title-field-container">
                                      <span className="text-field-style">
                                          Statistiques de vos réservations passées
                                          {/*(jusqu'au {new Date().toLocaleString('fr-FR', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric',
                                      })}*/}
                                      </span>
                            </div>
                        </div>
                        <div className="title-field-container" style={{marginTop:"20px"}}>
                                      <span className="text-field-style">
                                          Année
                                      </span>
                        </div>

                        <ChipYears yearsSince2023={yearsSince2023} onQueryChange={yearChanged} />

                        <div className="title-field-container" style={{marginTop:"20px"}}>
                                      <span className="text-field-style">
                                          Mois
                                      </span>
                        </div>

                        <ChipMonths months={monthNames} onQueryChange={monthChanged}></ChipMonths>

                        <div style={{width:"100%", marginTop:"40px",
                            display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                            {showNumberPrestaSkeleton ? (
                                    <Skeleton style={{borderRadius: "15px"}} variant="rectangular"
                                              width="120px" height={110}/>
                            )
                                : (
                                    <span style={{fontWeight:1000, fontSize:"98px", lineHeight:"110px"}}>{numberOfResa}</span>
                                )
                            }
                            <span style={{fontWeight:600, fontSize:"20px"}}>réservations</span>

                        </div>
                        <div className="button-container" style={{marginTop: "70px"}}>
                            <Button style={{marginBottom: "100px"}} className="button-style-nogr button-style-borders"
                                    disableElevation variant="contained" onClick={navigateHome}>
                                <Typography variant="button" style={{textTransform: 'none'}}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                                      message={snackMessage} style={{width: "100%"}}/>
                    </div>
                )}
        </div>
    )
}